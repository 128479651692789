import React, { createContext, useEffect, useState, useContext } from 'react';
import { User, onAuthStateChanged } from 'firebase/auth';
import { auth } from '../../services/Firebase';

const defaultContext = {
  currentUser: null as User | null,
};

export const AuthContext = createContext(defaultContext);
export const useAuth = () => useContext(AuthContext);

const AuthProvider: React.FC = ({ children }: any) => {
  const [currentUser, setCurrentUser] = useState<User | null>(null);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
    });
  }, []);

  return <AuthContext.Provider value={{ currentUser }}>{children}</AuthContext.Provider>;
};

export function useAuthValue() {
  return useContext(AuthContext);
}

export default AuthProvider;
