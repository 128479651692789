import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  CloseButton,
  Container,
  HStack,
  useDisclosure,
} from '@chakra-ui/react';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { Alert as AlertType } from '../../types';

type AlertsContextType = {
  alert: AlertType;
  setAlert: (alert: AlertType) => void;
};

type AlertsProviderProps = {
  children: React.ReactNode;
};
const defaultContext: AlertsContextType = {
  alert: {
    display: 'None',
    type: 'info',
    title: 'Test Alert',
    description: 'This is a test alert',
  },
  setAlert: (alert: AlertType) => {},
};

export const AlertsContext = createContext(defaultContext);
export const useAlerts = () => useContext(AlertsContext);

const AlertsProvider = ({ children }: AlertsProviderProps) => {
  const [alert, setAlert] = useState<AlertType>(defaultContext.alert);
  defaultContext.setAlert = setAlert;

  const onClose = () => {
    setAlert({ ...alert, display: 'None' });
  };
  useEffect(() => {}, [alert]);

  return <AlertsContext.Provider value={{ alert, setAlert }}>{children}</AlertsContext.Provider>;
};

export default AlertsProvider;
