import React from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import AuthProvider from '../components/Authentication/AuthContext';
import AlertsProvider from '../components/Alerts/AlertsContext';
import theme from '../theme';

export const wrapRootElement = ({ element }) => (
  <ChakraProvider theme={theme}>
    <AuthProvider>
      <AlertsProvider>{element}</AlertsProvider>
    </AuthProvider>
  </ChakraProvider>
);
