import { FirebaseApp, initializeApp } from 'firebase/app';
import { Auth, getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyC_ICCH8Dql61Qd5CmGJmBQYOA5zoKwNaE',
  authDomain: 'dudejustgo-148eb.firebaseapp.com',
  projectId: 'dudejustgo-148eb',
  storageBucket: 'dudejustgo-148eb.appspot.com',
  messagingSenderId: '1009039868525',
  appId: '1:1009039868525:web:d067d51e9cca2c6a643564',
};

let app: FirebaseApp | undefined = undefined;
let auth: Auth;

if (typeof window !== 'undefined') {
  app = app || initializeApp(firebaseConfig);
  auth = getAuth(app);
}

export { auth };
